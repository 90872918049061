import queryString from "query-string";

import { versionCompare, versionParse } from "./version";

// Detect Fellow loading in various iframes via the browser extension
let queryParams = queryString.parse(window.location.search);
if (queryParams.failed && typeof queryParams.next === "string") {
	const url = new URL(`${window.ROOT_URL}${queryParams.next}`);
	queryParams = queryString.parse(url.search);
}

type AppQueryParam = "google" | "msft" | null;

const paramsVideocall = (sessionStorage.getItem("lib.inVideocall") || queryParams.in_videocall) as AppQueryParam;
const paramsCalendar = (sessionStorage.getItem("lib.inCalendar") || queryParams.in_calendar) as AppQueryParam;
export const extensionVersion = (sessionStorage.getItem("lib.extensionVersion") || queryParams.version) as
	| string
	| null;

const inIframe = window.self != window.top;

/**
 * Determine if the app is running in Google Meet via the Browser Extension
 */
export const inGoogleMeet =
	inIframe &&
	(paramsVideocall === "google" ||
		window.location.href.includes("meet_url") ||
		queryParams.next?.includes("meet.google.com"));

/**
 * Determine if the app is running in Google Calendar via the Browser Extension
 */
export const inGoogleCalendar =
	inIframe && (paramsCalendar === "google" || window.location.href.includes("in_google_calendar"));

/**
 * Determine if the app is running in MS Teams via the Browser Extension
 */
export const inMSTeams = inIframe && paramsVideocall === "msft";

/**
 * Determine if the app is running in MS Outlook via the Browser Extension
 */
export const inMSOutlook = inIframe && paramsCalendar === "msft";

/**
 * Determine if the app is running in Google Meet or MS Teams (via the Browser Extension)
 */
export const inVideocall = inGoogleMeet || inMSTeams;

/**
 * Determine if the app is running in Google Calendar or MS Outlook (via the Browser Extension)
 */
export const inCalendar = inGoogleCalendar || inMSOutlook;

export function isExtensionVersionReleased(versionToCheck: string): boolean {
	if (!extensionVersion) {
		// If we can't get the current extension version, assume versionToCheck hasn't been released yet
		return false;
	}
	const versionToCheckParsed = versionParse(versionToCheck);
	const extensionVersionParsed = versionParse(extensionVersion);
	return versionCompare(versionToCheckParsed, extensionVersionParsed) <= 0;
}

/**
 * Checks if the Fellow browser extension is installed by looking for a specific meta tag in the document.
 *
 * @returns {boolean}
 */
export function isExtensionInstalled(): boolean {
	return !!document.querySelector('meta[name="fellow-browser-extension"]');
}

if (queryParams.in_calendar) sessionStorage.setItem("lib.inCalendar", queryParams.in_calendar as string);
if (queryParams.in_videocall) sessionStorage.setItem("lib.inVideocall", queryParams.in_videocall as string);
if (queryParams.version) sessionStorage.setItem("lib.extensionVersion", queryParams.version as string);

export function closeMeetSidePanel(extraData?: { [key: string]: unknown }) {
	window.parent.postMessage(
		{
			type: "MEET_CLOSE_SIDE_PANEL",
			...extraData,
		},
		"*",
	);
}
