import React from "react";

import { noop } from "lodash";

type Setter<T> = (newValueOrUpdater: T | ((oldValue: T) => T)) => void;
type FaaC<T, P = {}> = React.ComponentType<P & { children: (t: T) => React.ReactElement | null }>;

interface MutableContext<T> {
	Consumer: FaaC<T>;
	Mutator: FaaC<Setter<T>>;
	Provider: React.ComponentType<{ value: T }>;

	useValue: () => T;
	useSetter: () => Setter<T>;
}

// Removes a possible Context suffix from name
function stripContextSuffix(name: string): string {
	if (name.slice(-"Context".length) === "Context") {
		return name.slice(0, -"Context".length);
	}
	return name;
}

export function createMutableContext<T>(name: string, defaultValue: T): MutableContext<T> {
	type Context = MutableContext<T>;

	const baseName = stripContextSuffix(name);

	const context = React.createContext(defaultValue);
	context.displayName = `${baseName}Context`;
	const setterContext = React.createContext<Setter<T>>(noop);
	setterContext.displayName = `${baseName}SetterContext`;

	function useValue() {
		return React.useContext(context);
	}

	const Consumer: Context["Consumer"] = ({ children }) => {
		return children(useValue());
	};

	function useSetter(): Setter<T> {
		return React.useContext(setterContext);
	}

	const Mutator: Context["Mutator"] = ({ children }) => {
		return children(useSetter());
	};

	const Provider: Context["Provider"] = ({ value, children }) => {
		const [currentValue, setValue] = React.useState(value);

		return (
			<setterContext.Provider value={setValue}>
				<context.Provider value={currentValue}>{children}</context.Provider>
			</setterContext.Provider>
		);
	};

	return {
		useValue,
		useSetter,
		Consumer,
		Mutator,
		Provider,
	};
}

interface ObjectContext<O extends {}> {
	Consumer: <K extends keyof O>(props: {
		field: K;
		children: (value: O[K]) => React.ReactElement | null;
	}) => React.ReactNode;
	Provider: React.ComponentType<{ value: O }>;

	useField<K extends keyof O>(field: K): O[K];
}

export function createObjectContext<O extends {}>(name: string, defaultValue: O): ObjectContext<O> {
	type Context = ObjectContext<O>;
	type Contexts = { [K in keyof O]: React.Context<O[K]> };

	const baseName = stripContextSuffix(name);

	const partialContexts: Partial<Contexts> = {};
	for (const key of Object.keys(defaultValue) as (keyof O)[]) {
		const ctx = React.createContext(defaultValue[key]);
		ctx.displayName = `${baseName}_${String(key)}_Context`;
		partialContexts[key] = ctx;
	}

	const contexts: Contexts = partialContexts as Contexts;

	function useField<K extends keyof O>(field: K): O[K] {
		return React.useContext(contexts[field]);
	}

	const Consumer: Context["Consumer"] = ({ field, children }) => {
		return children(useField(field));
	};

	const Provider: Context["Provider"] = ({ value, children }) => {
		const keys = Object.keys(contexts) as (keyof O)[];
		return (
			<>
				{keys.reduce((acc, key) => {
					const Provider = contexts[key].Provider;
					return <Provider value={value[key]}>{acc}</Provider>;
				}, children)}
			</>
		);
	};

	return {
		useField,
		Consumer,
		Provider,
	};
}
