import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";

import { SplashScreen } from "@capacitor/splash-screen";
import cx from "classnames";

import { ReactComponent as LoaderAnimation } from "~/assets/loader.svg";

/**
 * A global page loader indicator. This component will render an animated
 * loader on first render, fading out when no network requests have happened
 * for a short time (up to a maximum of 10 seconds).
 */
export default function Lowda() {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const networkDone = new Promise(resolve => {
			document.addEventListener("network-quiesced", resolve, { once: true });
		});

		const timeout = new Promise(resolve => setTimeout(resolve, 10000));

		Promise.race([networkDone, timeout]).then(() => {
			setLoaded(true);
			SplashScreen.hide();
		});
	}, []);

	return (
		<CSSTransition in={!loaded} timeout={200} unmountOnExit appear>
			<div className="fellow-loader">
				<LoaderAnimation />
			</div>
		</CSSTransition>
	);
}

export function StaticLowda() {
	return (
		<div className={cx("fellow-loader", "static")}>
			<LoaderAnimation />
		</div>
	);
}
