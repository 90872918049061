import React from "react";

import { StatusBar, Style as StatusBarStyle } from "@capacitor/status-bar";
import { noop } from "lodash";

import { inGoogleMeet } from "~/lib/browserExtension";
import { inCompanionMode, isiOSApp, isNative } from "~/lib/utils";

type ThemeType = "DARK" | "LIGHT" | "AUTO" | null;

export type ThemeContextInterface = {
	isDarkMode: boolean;
	theme: ThemeType;
	setTheme: (theme: ThemeType) => void;
};

const matchDarkMode = window.matchMedia?.("(prefers-color-scheme: dark)");

function getDarkMode() {
	return document.documentElement.dataset.theme === "theme-dark";
}

function isTheme(theme: string | null): theme is ThemeType {
	return ["DARK", "LIGHT", "AUTO", null].includes(theme);
}

const ThemeContext = React.createContext<ThemeContextInterface>({
	isDarkMode: getDarkMode(),
	theme: null,
	setTheme: noop,
});

export const ThemeContextProvider: React.FC<{ theme: string | null }> = props => {
	const [isDarkMode, setDarkMode] = React.useState(getDarkMode());
	const [theme, setTheme] = React.useState<ThemeType>(isTheme(props.theme) ? props.theme : null);

	const setSystemTheme = React.useCallback(() => {
		const isDark = matchDarkMode?.matches;
		if (isDark) {
			document.documentElement.dataset.theme = `theme-dark`;
			setDarkMode(true);
		} else {
			document.documentElement.dataset.theme = `theme-light`;
			setDarkMode(false);
		}
		if (isiOSApp) StatusBar.setStyle({ style: StatusBarStyle.Default });

		if (!inCompanionMode) {
			window.electron?.menuTheme.system();
		}
	}, [setDarkMode]);

	const setLightTheme = React.useCallback(() => {
		document.documentElement.dataset.theme = `theme-light`;
		if (isiOSApp) StatusBar.setStyle({ style: StatusBarStyle.Light });
		if (!inCompanionMode) {
			window.electron?.menuTheme.light();
		}
		setDarkMode(false);
	}, [setDarkMode]);

	const setDarkTheme = React.useCallback(() => {
		document.documentElement.dataset.theme = `theme-dark`;
		if (isiOSApp) StatusBar.setStyle({ style: StatusBarStyle.Dark });
		if (!inCompanionMode) {
			window.electron?.menuTheme.dark();
		}
		setDarkMode(true);
	}, [setDarkMode]);

	const onSystemThemeChange = React.useCallback(() => {
		setSystemTheme();
	}, [setSystemTheme]);

	React.useEffect(() => {
		if (!theme && props.theme && isTheme(props.theme)) setTheme(props.theme);
	}, [theme, props.theme, setTheme]);

	React.useEffect(() => {
		if (!theme) return;

		// Always use light theme within the browser extension
		if (inGoogleMeet) {
			return setLightTheme();
		}

		/* Native Mobile
		 * Follows OS theme always
		 * Other than on the auth screens that don't have dark theme
		 * Makes sure the status bar colour is updated (e.g. on auth, where it overwrites the native theme)

		 * Teams
		 * In sidebar locked to dark theme
		 * Normal context switches back and forth based on container
		 */
		if (theme == "AUTO" || isNative) {
			matchDarkMode?.addListener(onSystemThemeChange);
			onSystemThemeChange();
			return;
		}

		theme == "DARK" ? setDarkTheme() : setLightTheme();
		matchDarkMode?.removeListener(onSystemThemeChange);

		return () => matchDarkMode?.removeListener(onSystemThemeChange);
	}, [theme, onSystemThemeChange, setDarkTheme, setLightTheme]);

	const value = React.useMemo(() => ({ isDarkMode, theme, setTheme }), [isDarkMode, theme, setTheme]);

	return <ThemeContext.Provider value={value}>{props.children}</ThemeContext.Provider>;
};

export default ThemeContext;
