import React from "react";

import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import cx from "classnames";

import styles from "./RadioGroup.module.css";

export interface RadioOption<T extends string> {
	value: T;
	label: React.ReactNode;
	disabled?: boolean;
}

export interface RadioGroupProps<T extends string> {
	name: string;
	value: T | undefined;
	options: RadioOption<T>[];
	className?: string;
	readonly?: boolean;
	disabled?: boolean;
	onChange?: (value: T) => void;
}

export default function RadioGroup<T extends string>({
	className,
	options,
	disabled: groupDisabled,
	value: currentValue,
	onChange,
}: RadioGroupProps<T>) {
	return (
		<RadixRadioGroup.Root
			className={cx(className, styles.radioGroupRoot)}
			value={currentValue}
			onValueChange={onChange}
		>
			{options.map(({ value: optionValue, label, disabled: optionDisabled }) => (
				<label key={optionValue} className={styles.radioOption}>
					<RadixRadioGroup.Item
						value={optionValue}
						disabled={optionDisabled || groupDisabled}
						className={styles.optionButton}
					>
						<RadixRadioGroup.Indicator className={styles.optionIndicator} />
					</RadixRadioGroup.Item>
					<span className={styles.optionLabel}>{label}</span>
				</label>
			))}
		</RadixRadioGroup.Root>
	);
}
