function initScrollTo() {
	if (!HTMLElement.prototype.scrollTo) {
		HTMLElement.prototype.scrollTo = function (...args: [left: number, top: number] | [options?: ScrollToOptions]) {
			let left: number | undefined, top: number | undefined;
			if (args.length === 2) {
				[left, top] = args;
			} else if (args[0]) {
				({ left, top } = args[0]);
			} else {
				return;
			}
			if (left !== undefined) this.scrollLeft = left;
			if (top !== undefined) this.scrollTop = top;
		};
	}
}

export function initBrowserSupports() {
	// NOTE: remove this code, once we stop supporting old MS Edge
	initScrollTo();
}
