import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";

interface DeviceInfo {
	id: string;
	platform: string;
}

export async function getInfo(): Promise<DeviceInfo> {
	// Fetch all these in parallel
	const deviceIdPromise = Device.getId();
	const deviceInfoPromise = Device.getInfo();
	const { identifier: id } = await deviceIdPromise;
	const { platform } = await deviceInfoPromise;

	return { id, platform };
}

export async function getNativeAppVersion(): Promise<string> {
	const appInfoPromise = App.getInfo();
	const { version: appVersion } = await appInfoPromise;

	return appVersion;
}

export async function getIdentifier(): Promise<string> {
	return Device.getId().then(info => info.identifier);
}

// Used by the desktop app
// TODO: [NATIVEUPDATE] Remove after native app update
window.getDeviceIdentifier = getIdentifier;
