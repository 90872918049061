import zoomSdk from "@zoom/appssdk";

import { isZoom } from "~/lib/utils";

export async function configureZoom() {
	if (!isZoom) return;
	return zoomSdk.config({
		capabilities: [
			"onSendAppInvitation",
			"getRunningContext",
			"getSupportedJsApis",
			"openUrl",
			"authorize",
			"onAuthorized",
			"promptAuthorize",
		],
	});
}

export async function zoomOpenUrl(url: string) {
	await configureZoom();
	zoomSdk.openUrl({ url });
}

if (isZoom) {
	// Catch organic click events on all links
	document.addEventListener("click", function (event) {
		const element = event.target as HTMLLinkElement;
		// Don't open note links directly from doc
		if (element.tagName == "A" && element.className != "prose-link") {
			const href = element.href.toString();
			const url = new URL(`${window.location.origin}/zoom/redirect`);
			// eslint-disable-next-line camelcase
			const params = new URLSearchParams({ redirect_url: href });

			if (!href.includes(window.location.origin)) {
				zoomOpenUrl(`${url}?${params.toString()}`);
			}

			event.stopPropagation();
		}
	});
}

export default zoomSdk;
