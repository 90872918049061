import React from "react";
import { graphql } from "react-relay";

import cookie from "js-cookie";
import { noop } from "lodash";

import { promptNativeOAuthAndroid, promptNativeOAuthIOS } from "~/lib/auth/nativeOAuth";
import { openWindowCentered } from "~/lib/popup";
import { resetAnalyticsUser } from "~/lib/segment";
import { desktopVersion as getDesktopVersion, mobileVersion, Version, versionLte } from "~/lib/version";
import { zoomOpenUrl } from "~/lib/zoom";
import { AuthFlowType } from "~/types/main.d";

import growthbook from "../ab/growthbook";
import { inCalendar, inVideocall } from "../browserExtension";
import { isAndroidApp, isiOSApp, isNative, isZoom, simpleHash } from "../utils";
import { nativeGoogleLogin, signOutGoogle } from "./nativeGoogle";
import { nativeOfficeLogin, signOutOffice } from "./nativeOffice";

cookie.defaults.secure = true;

graphql`
	query authContextQuery {
		viewer {
			user {
				type
			}
		}
	}
`;

export interface AuthContextType extends AuthFlowType {
	setAuthContext: (state: AuthFlowType, callback?: () => void) => void;
}

export interface AuthPostMessage {
	user: FellowUser;
	account: FellowAccount;
	result: string;
	channel: string;
	// used by outlook to pass CSRF token back in,
	// given that we can't read cookies from the outlook iframe till we finish the popup flow
	csrf_token?: string; // eslint-disable-line camelcase
	message: string;
	code: string | null;
	email?: string;
	signed_email?: string; // eslint-disable-line camelcase
	oauth_email?: string; // eslint-disable-line camelcase
	signed_oauth_email?: string; // eslint-disable-line camelcase
	next?: string;
	signup?: AuthFlowType;
	app_config?: FellowAppConfig; // eslint-disable-line camelcase
}

export function setWindowProperties(data: AuthPostMessage) {
	if (data.csrf_token) {
		window.CSRF_TOKEN = data.csrf_token;
	}
	if (data.app_config) {
		window.APP_CONFIG = data.app_config;
	}

	// We don't expect analytics_context to have a context or context.app property but to avoid overwriting them
	// if they're ever added
	const analyticsContextContext = (data.user?.analytics_context as { context?: object })?.context ?? {};
	const analyticsContextContextApp = (analyticsContextContext as { app?: object })?.app ?? {};

	if (data.user) {
		const desktopVersion = getDesktopVersion();

		window.analytics.identify(
			data.user.id.toString(),
			{
				...data.user.analytics_traits,
				// eslint-disable-next-line camelcase
				is_desktop_app: !!desktopVersion,
				// eslint-disable-next-line camelcase
				...(desktopVersion ? { desktop_app_version: `FellowElectron:${desktopVersion.join(".")}` } : {}),
			},
			{
				...data.user.analytics_context,
				context: {
					...analyticsContextContext,
					...(desktopVersion
						? {
								app: {
									...analyticsContextContextApp,
									name: "Fellow",
									version: `FellowElectron:${desktopVersion.join(".")}`,
								},
							}
						: {}),
				},
				integrations: { "Facebook Pixel": true },
			},
		);
		if (data.user.email) {
			window.ga?.("set", "userId", simpleHash(data.user.email));
			// window.heap?.identify(simpleHash(data.user.email));
		}
	}
}

export async function signOutMobile() {
	if (isNative) return Promise.all([signOutGoogle(), signOutOffice()]).then(noop);
}

export function generateAndSubmitLogoutForm(actionUrl: string) {
	const form = document.createElement("form");
	form.method = "POST";
	form.action = actionUrl;
	const csrfTokenField = document.createElement("input");
	csrfTokenField.type = "hidden";
	csrfTokenField.name = "csrfmiddlewaretoken";
	csrfTokenField.value = window.CSRF_TOKEN;
	form.appendChild(csrfTokenField);
	document.body.appendChild(form);
	form.submit();
}

export function signOut(redirect = true) {
	resetAnalyticsUser();
	signOutMobile();

	window.Intercom?.("shutdown");
	if (isZoom && redirect) {
		generateAndSubmitLogoutForm("/auth/zoom/logout/");
	} else if (redirect) {
		generateAndSubmitLogoutForm("/auth/logout/");
	}
}

/**
 * An auth function that uses a capacitor api for login on iOS and android.
 * Note that for android function will only work for login routes that end with oauth_redirect_popup and have been modified
 * to display a button on the last step.
 * For iOS, it'll only work on login routes that have been modified to redirect to a url encoded scheme on the last step.
 */
async function promptNativeOrBrowserOAuth(path: string, oAuthParams: OAuthParams): Promise<AuthPostMessage> {
	if (isAndroidApp) {
		const currentMobileVersion = await mobileVersion().catch(() => [1, 2, 0] as Version);

		if (versionLte(currentMobileVersion, [1, 2, 0])) {
			return Promise.reject({ code: 10034, message: "Please update your Fellow App." });
		} else {
			return promptNativeOAuthAndroid(path, oAuthParams);
		}
	} else if (isiOSApp) {
		const currentMobileVersion = await mobileVersion().catch(() => [1, 2, 0] as Version);

		if (versionLte(currentMobileVersion, [1, 4, 0])) {
			return Promise.reject({ code: 10034, message: "Please update your Fellow App." });
		} else {
			return promptNativeOAuthIOS(path, oAuthParams);
		}
	}
	return promptOAuth(path, oAuthParams);
}

export const AuthContext: React.Context<AuthContextType> = React.createContext({
	setAuthContext: noop,
});
AuthContext.displayName = "AuthContext";

let lastCallback: null | ((data: AuthPostMessage) => void) = null;

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends React.Component<{}, AuthContextType> {
	constructor(props: {}) {
		super(props);

		this.state = {
			// eslint-disable-next-line react/no-unused-state
			setAuthContext: this.setAuthContext.bind(this),
		};
	}

	onWindowMessage = (event: MessageEvent) => {
		if (event.data?.channel === "identity.auth") {
			// Verify origin of message events
			if (event.origin !== window.ROOT_URL && event.origin !== window.location.origin) {
				console.warn(
					`Received message from invalid origin. Expected ${window.ROOT_URL}, received ${event.origin}`,
					event.data,
				);
				return;
			}

			const data = event.data as AuthPostMessage;

			if (!lastCallback && !window.Cypress) {
				// There's no callback registered, meaning this page was probably refreshed while
				// the oauth popup was open.
				// Cypress will trigger this without a callback
				return;
			}

			if (data.result === "ok") {
				setWindowProperties(data);
				if (data.signup) return this.setAuthContext(data.signup, () => lastCallback?.(data));
			}

			lastCallback?.(data);
		}
	};

	authUpdated = (data: AuthPostMessage) => {
		return new Promise(resolve => {
			this.setAuthContext(data, () => resolve(data));
		});
	};

	workspacesReceived = (event: Event) => {
		// @ts-ignore
		this.authUpdated(event.detail.authData);
	};

	setAuthContext = (state: AuthFlowType, callback?: () => void) => {
		this.setState(state, callback);
	};

	componentDidMount(): void {
		const { user, account, signup } = window.INITIAL_STATE;
		this.setAuthContext({ user, account, ...signup });

		window.addEventListener("message", this.onWindowMessage);
		window.addEventListener("zoomWorkspacesReady", this.workspacesReceived);
	}

	componentWillUnmount(): void {
		window.removeEventListener("message", this.onWindowMessage);
		window.removeEventListener("zoomWorkspacesReady", this.workspacesReceived);
	}

	render(): React.ReactElement {
		return <AuthContext.Provider value={this.state}>{this.props.children}</AuthContext.Provider>;
	}
}

export type OAuthParamsSignup = "generic" | "personal" | "work";

export interface OAuthParams {
	signup?: OAuthParamsSignup;
	refresh?: boolean;
	connect?: boolean;
	docs?: boolean;
	accountID?: string | number;
	inviteCode?: string;
	inviteLinkCode?: string;
	templateID?: string;
	templateContentId?: string;
	streamInviteID?: string;
	integrationTag?: string;
	topicTag?: string;
	scopes?: string;
	treatment?: string;
	teams?: boolean;
	msTodo?: boolean;
	referrer?: string;
	requestAutoAddTabScope?: boolean;
	slackRequestUserScopes?: boolean;
	recapKey?: string;
	redirectBackToDesktopOnLastOauthStep?: boolean;
	redirectTicketToURLEncodedSchemeOnLastOauthStep?: boolean;
}

export function getOAuthPromptParams({
	refresh = false,
	connect = false,
	docs = false,
	teams = false,
	msTodo = false,
	requestAutoAddTabScope = false,
	slackRequestUserScopes = false,
	redirectBackToDesktopOnLastOauthStep = false,
	redirectTicketToURLEncodedSchemeOnLastOauthStep = false,
	signup,
	accountID,
	inviteCode,
	inviteLinkCode,
	treatment,
	templateID,
	templateContentId,
	integrationTag,
	streamInviteID,
	topicTag,
	scopes,
	referrer,
	recapKey,
}: OAuthParams) {
	let { login_redirect: loginRedirect } = window.INITIAL_STATE;
	if (!loginRedirect) {
		const currentParams = new URLSearchParams(window.location.search);
		loginRedirect = currentParams.get("next") ?? undefined;
	}

	let params = new URLSearchParams({
		refresh: refresh.toString(),
		connect: connect.toString(),
		docs: docs.toString(),
		teams: teams.toString(),
		/* eslint-disable camelcase */
		ms_todo: msTodo.toString(),
		request_auto_add_tab_scope: requestAutoAddTabScope.toString(),
		slack_request_user_scopes: slackRequestUserScopes.toString(),
		/* eslint-enable camelcase */
	});

	if (signup) params.append("signup", signup);
	if (accountID) params.append("account_id", String(accountID));
	if (inviteCode) params.append("invite_code", String(inviteCode));
	if (inviteLinkCode) params.append("invite_link_code", String(inviteLinkCode));
	if (templateID) params.append("template_id", String(templateID));
	if (templateContentId) params.append("template_content_id", String(templateContentId));
	if (integrationTag) params.append("integration_tag", String(integrationTag));
	if (topicTag) params.append("topic_tag", String(topicTag));
	if (referrer) params.append("referrer", referrer);
	if (treatment) params.append("treatment", treatment);
	if (streamInviteID) params.append("stream_invite", streamInviteID);
	if (scopes) params.append("scopes", scopes);
	if (loginRedirect) params.append("login_redirect", loginRedirect);
	if (window.location.origin) params.append("origin", window.location.origin);
	if (inVideocall || inCalendar) params.append("extension", "true");
	if (recapKey) params.append("recap_key", recapKey);
	if (redirectBackToDesktopOnLastOauthStep) params.append("redirectBackToDesktopOnLastOauthStep", "1");
	if (redirectTicketToURLEncodedSchemeOnLastOauthStep)
		params.append("redirectTicketToURLEncodedSchemeOnLastOauthStep", "1");

	return params;
}

export function getOAuthPromptUri(path: string) {
	return `${window.location.origin}${path}`;
}

function promptOAuth(path: string, oAuthParams: OAuthParams): Promise<AuthPostMessage> {
	// This functions is highly coupled to desktop/src/main.ts::oauthWindowOpenHandler
	// for the desktop app and it relies on path to include "/oauth/" in order to
	// modify the behavior of the window.open call.
	return new Promise((resolve, reject) => {
		const uri = getOAuthPromptUri(path);

		const params = getOAuthPromptParams(oAuthParams);

		if (isZoom) {
			zoomOpenUrl(`${uri}?${params.toString()}`);
		} else if (params.get("redirectBackToDesktopOnLastOauthStep") === "1") {
			window.open(`${uri}?${params.toString()}`, "_self");
		} else {
			openWindowCentered(`${uri}?${params.toString()}`, "oauth", 480, 720);
		}

		lastCallback = data => {
			if (data.result === "ok") {
				resolve(data);
			} else {
				reject(data);
			}

			lastCallback = null;
		};
	});
}

export async function promptGoogleAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	if (isNative) {
		return nativeGoogleLogin(params, 3);
	}

	return promptOAuth("/google/oauth/", params);
}

export async function promptOffice365Auth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	if (isNative) {
		return nativeOfficeLogin(params);
	}

	return promptOAuth("/office365/oauth/", params);
}

export async function promptOutlookOffice365Auth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/apps/outlook/login/popup/", params);
}

export async function promptOffice365AuthAdmin(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/office365/oauth/admin/", params);
}

export function promptSlackAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/slack/oauth/", { ...params, slackRequestUserScopes: growthbook.isOn("slack-status") });
}

export function promptAsanaAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/asana/oauth/", params);
}

export function promptSalesforceAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/salesforce/oauth/", params);
}

export function promptHubspotAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/hubspot/oauth/", params);
}

export async function promptOktaAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptNativeOrBrowserOAuth("/okta/oauth/", params);
}

export function promptOneLoginAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptNativeOrBrowserOAuth("/one_login/oauth/", params);
}

export function promptCustomAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptNativeOrBrowserOAuth("/sso/custom_oidc/oauth/", params);
}

export function promptClickupAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/apps/clickup/oauth/", params);
}

export function promptTrelloAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/apps/trello/oauth/", params);
}

export function promptMondayAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/apps/monday/oauth/", params);
}

export function promptLinearAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/apps/linear/oauth/", params);
}

export function promptNotionAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/apps/notion/oauth/", params);
}

export function promptConfluenceAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/apps/confluence/oauth/", params);
}

export function promptZoomBotAuth(params: OAuthParams = {}): Promise<AuthPostMessage> {
	return promptOAuth("/zoom/bot/oauth/", params);
}
