import React, { Component } from "react";

import { faWifiSlash } from "@awesome.me/kit-4c83d50d0d/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bowser from "bowser";
import { Header, Icon } from "semantic-ui-react";

import history from "~/lib/history";
import { globalFetchKeyContext } from "~/lib/relayUtil";
import { CtrlOrCmd, isDesktopApp, isNative, UpOrOption } from "~/lib/utils";
import { Button } from "~/ui";

import styles from "./css/FullPageError.module.css";

interface FullPageErrorProps {
	message: string;
}

export function nonNativeInstructions() {
	const browser = bowser.getParser(window.navigator.userAgent);
	const keyCombination =
		browser.is("macOS") || isDesktopApp ? (
			<>
				<kbd>{CtrlOrCmd}</kbd> + <kbd>{UpOrOption}</kbd> + <kbd>R</kbd>
			</>
		) : (
			<>
				<kbd>{CtrlOrCmd}</kbd> + <kbd>F5</kbd>
			</>
		);

	if (browser.is("Desktop")) {
		return (
			<Header.Subheader>
				You can try hard-refreshing this page with the keyboard shortcut {keyCombination}
			</Header.Subheader>
		);
	} else {
		return <Header.Subheader>You can try refreshing this page</Header.Subheader>;
	}
}

export default class FullPageError extends Component<FullPageErrorProps> {
	goBack() {
		history.go(-1);
	}

	goHome() {
		window.location.pathname = "/";
	}

	reload() {
		window.location.reload();
	}

	getErrorInstructions() {
		if (isNative) {
			return (
				<div className={styles.oopsButtonContainer}>
					<Button size="wide" round onClick={this.goBack}>
						Go Back
					</Button>
					<Button size="wide" round onClick={this.goHome}>
						Go Home
					</Button>
					<Button size="wide" round onClick={this.reload}>
						Retry
					</Button>
				</div>
			);
		}

		return nonNativeInstructions();
	}

	render(): React.ReactNode {
		const { message } = this.props;

		return (
			<Header as="h2" icon className={styles.oops} style={{ lineHeight: "1.5em" }}>
				<Icon name="warning sign" color="red" />
				{message}
				{this.getErrorInstructions()}
			</Header>
		);
	}
}

export function FullPageOffline({ retry }: { retry: () => void }) {
	const setGlobalFetchKey = globalFetchKeyContext.useSetter();
	const updateGlobalFetchKeyAndRetry = React.useCallback(() => {
		setGlobalFetchKey(n => n + 1);
		retry();
	}, [setGlobalFetchKey, retry]);

	React.useEffect(() => {
		// If we go online, retry
		window.addEventListener("online", updateGlobalFetchKeyAndRetry);
		return () => window.removeEventListener("online", updateGlobalFetchKeyAndRetry);
	}, [updateGlobalFetchKeyAndRetry]);

	return (
		<Header as="h2" icon className={styles.oops} style={{ lineHeight: "1.5em" }}>
			<FontAwesomeIcon icon={faWifiSlash} />
			&nbsp;No internet connection
			<br />
			<Button onClick={updateGlobalFetchKeyAndRetry} size="wide" round>
				Try again
			</Button>
		</Header>
	);
}
