import React, { useCallback, useEffect, useState } from "react";

import { debounce, isEqual, mapValues } from "lodash";

export enum ScreenSize {
	extraSmall = 280, // MS Teams side panel
	iphoneSE = 320,
	small = 479,
	mobile = 736, // @largestMobileScreen in site.variables
	computer = 980, // @computerBreakpoint in site.variables
	largeMonitor = 1280, // @largeMonitorBreakpoint in site.variables
	widescreen = 1600, // @widescreenMonitorBreakpoint in site.variables
	ultraWidescreen = 1980, // @ultraWideMonitorBreakpoint in site.variables
}

export const Viewport = {
	isExtraSmallScreen: () => window.innerWidth <= ScreenSize.extraSmall,
	isIPhoneSE: () => window.innerWidth <= ScreenSize.iphoneSE,
	isSmallScreen: () => window.innerWidth <= ScreenSize.small,
	isMobile: () => window.innerWidth < ScreenSize.mobile,
	isTablet: () => window.innerWidth >= ScreenSize.mobile,
	isComputer: () => window.innerWidth >= ScreenSize.computer,
	isLargeMonitor: () => window.innerWidth >= ScreenSize.largeMonitor,
	isWidescreen: () => window.innerWidth >= ScreenSize.widescreen,
	isUltraWidescreen: () => window.innerWidth >= ScreenSize.ultraWidescreen,
};

export type ViewportContextInterface = { [key in keyof typeof Viewport]: boolean };

function getNewState(): ViewportContextInterface {
	return mapValues(Viewport, v => v());
}

const ViewportContext = React.createContext<ViewportContextInterface>(getNewState());

export const ViewportContextProvider: React.FC<{}> = ({ children }) => {
	const [state, setState] = useState(getNewState);

	const updateBreakpoints = useCallback(() => {
		const newState = getNewState();
		if (!isEqual(state, newState)) setState(newState);
	}, [state]);

	const debouncedUpdateBreakpoints = React.useMemo(() => {
		return debounce(updateBreakpoints, 500);
	}, [updateBreakpoints]);

	useEffect(() => {
		window.addEventListener("resize", debouncedUpdateBreakpoints);
		return () => window.removeEventListener("resize", debouncedUpdateBreakpoints);
	}, [debouncedUpdateBreakpoints]);

	return <ViewportContext.Provider value={state}>{children}</ViewportContext.Provider>;
};

export default ViewportContext;
